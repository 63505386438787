import React from "react";
import Helmet from "react-helmet";

import Container from "@components/Container";
import Layout from "@components/Layout";

export default () => {
    return (
        <Layout pageName="abnt">
            <Helmet>
                <title>SLS & Advogados Associados - LGPD</title>
            </Helmet>
            <div className="office">
                <Container>
                    <h3>PROTEÇÃO E PRIVACIDADE DE DADOS</h3>

                    <p>
            A <b>Sanches Lopes, Sales &amp; Advogados Associados </b>está comprometida com a
            observância e o cumprimento das leis que protegem a privacidade dos dados dos titulares.
                    </p>

                    <p>
            Realizamos o tratamento de dados apenas quando estritamente necessários para o
            cumprimento das determinações legais ou regulatórias, para execução de pré-contrato e
            contrato, bem como para possibilitar o exercício da advocacia nos processos
            administrativos, arbitrais e judiciais.
                    </p>

                    <h3>O QUE É A LGPD?</h3>
                    <p>
            Trata-se da sigla que representa a Lei Geral de Proteção de Dados Pessoais, Lei Federal
            nº 13.709, de 14 de agosto de 2018, que regula as atividades de tratamento de dados
            pessoais, inclusive nos meios digitais, por pessoa natural ou por pessoa jurídica de
            direito público ou privado.
                    </p>

                    <h3>O QUE É TRATAMENTO DE DADOS?</h3>

                    <p>
            Tratamento é toda operação realizada com os dados, como as que se referem a coleta,
            produção, recepção, classificação, utilização, acesso, reprodução, transmissão,
            distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou
            controle da informação, modificação, comunicação, transferência, difusão ou extração.
                    </p>

                    <h3>QUANDO SE APLICA A LGPD?</h3>

                    <ol type="a" style={{ paddingLeft: "50px" }}>
                        <li>
                Em qualquer operação de tratamento realizada por pessoa natural ou por pessoa
                jurídica de direito público ou privado;
                        </li>

                        <li>
                            No tratamento de dados realizados no território nacional;
                        </li>

                        <li>
                            Para ofertas de bens e serviços para pessoas no Brasil;
                        </li>

                        <li>
                            Nas interações de empresas com seus clientes e fornecedores;
                        </li>

                        <li>
                            Nas relações entre empregados e empregadores.
                        </li>
                    </ol>

                    <h3>QUANDO NÃO SE APLICA A LGPD?</h3>

                    <ol type="a" style={{ paddingLeft: "50px" }}>
                        <li>
                Quando pessoa natural realizar o tratamento para fins exclusivamente particulares e
                não econômicos;
                        </li>
                        <li>
                Quando o tratamento for realizado para fins exclusivamente jornalísticos e
                artísticos, acadêmicos, segurança pública, defesa nacional, segurança do Estado ou
                atividades de investigação e repressão de infrações penais;
                        </li>
                        <li>
                Ao tratamento de dados provenientes de fora do território nacional e que não sejam
                objeto de comunicação, uso compartilhado de dados com agentes de tratamento
                brasileiros ou objeto de transferência internacional de dados com outro país que não
                o de proveniência, desde que o país de proveniência proporcione grau de proteção de
                dados pessoais adequado ao previsto na LGPD.
                        </li>
                    </ol>

                    <h3>QUAIS SÃO OS DADOS PROTEGIDOS PELA LGPD?</h3>

                    <h4>- DADOS PESSOAIS:</h4>

                    <p>
            São todas as informações que permitem identificar ou tornam uma pessoa física
            identificável. Alguns exemplos são: nome, RG, CPF, dados de localização,{" "}
                        <em>cookies</em>, endereço IP, características físicas, dados profissionais, perfis e
            endereços eletrônicos.
                    </p>

                    <h4>- DADOS SENSÍVEIS</h4>
                    <p>
            São aqueles que se referem à origem racial ou étnica, convicção religiosa, opinião
            política, filiação a sindicato ou à organização de caráter religioso, filosófico ou
            político, relacionados à saúde ou à vida sexual, dados genéticos ou biométricos
            relativos à pessoa natural.
                    </p>

                    <h3>ENCARREGADO PELO TRATAMENTO DE DADOS PESSOAIS</h3>
                    <p>
            O Encarregado pelo Tratamento de Dados Pessoais é a pessoa indicada pelo Controlador
            para atuar como canal de comunicação com os titulares de dados e a Autoridade Nacional
            de Proteção de Dados (ANPD).
                    </p>

                    <p>
            Atendendo as exigências da LGPD, a
                        <b>Sanches Lopes, Sales &amp; Advogados Associados </b>nomeou o{" "}
                        <b>Dr. Luciano de Sales </b>como Encarregado pelo Tratamento de Dados Pessoais, podendo
            ser
                    </p>

                    <p>
            contatado através do e-mail <a href="mailto:encarregado@slsadvogados.com.br"> <u>encarregado@slsadvogados.com.br</u></a>,
            telefone (65) 3326-6454, ramal 215, sendo que os titulares poderão exercer os seus
            direitos ou esclarecer dúvidas relacionadas ao tratamento de dados pessoais com o mesmo.
                    </p>
                </Container>
            </div>
        </Layout>
    );
};
